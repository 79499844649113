import React from "react";
import {Helmet as Head} from "react-helmet";
import {graphql} from "gatsby";
import {get, take, first, isEmpty} from "lodash";
import {format as dateFormat} from "date-fns";
import DefaultLayout from "components/layout/default";
import OutlineText from "components/outline-text";
import BlogPostItemRow from "components/blog-post-item-row";
import layoutStyle from "styles/layout.module.css";

const ITEMS_PER_PAGE = 10;
const PAGE_DESCRIPTION = "Hear from our Horangi tech experts as we go deep into up-and-coming cyber threats, new solutions, and talk about the future of cybersecurity.";

function BlogTagTemplate(props) {
  let authorPostNodes = props.data.latestPosts.edges.map((item) => item.node);
  let [latestCount, setLatestCount] = React.useState(ITEMS_PER_PAGE);
  let [authorPostCollection, setAuthorPostCollection] = React.useState(authorPostNodes);

  function renderLatestPosts() {
    return authorPostCollection.map((item) => {
      return <BlogPostItemRow key={item.id} {...item}/>;
    });
  }

  function renderLoadMoreButton() {
    if (authorPostCollection.length === props.data.latestPosts.totalCount) return null;
    return <button
      className="p-4 border-b block w-full text-center leading-none focus:outline-none focus:shadow-outline hover:bg-gray-100"
      style={{transition: "all 320ms"}}
      onClick={handleLoadMoreClick}>
      Load more
    </button>;
  }

  async function handleLoadMoreClick(options = {}) {
    let data;
    try {
      let response = await fetch("/blog-feed.json");
      data = await response.json();
    } catch (error) {
      return;
    }
    let items = get(data, "items", []);
    if (isEmpty(items)) return false;
    items = items.filter((item) => {
      let tags = item._tags || [];
      return tags.includes(props.pageContext.tag);
    });
    let length = options.length;
    if (length == null) {
      length = authorPostCollection.length + ITEMS_PER_PAGE;
    }
    let newCollection = take(items, length);
    newCollection = newCollection.map((item) => {
      let urlParts = item.url.split("/");
      let slug = urlParts[urlParts.length - 2];
      return {
        id: item.id,
        fields: {
          slug,
          author: item._author
        },
        frontmatter: {
          title: item.title,
          excerpt: item.summary,
          category: first(item._category),
          featureImage: item.image,
          date: item.date_modified,
          formattedDate: dateFormat(item.date_modified, "MMM DD, YYYY")
        }
      };
    });
    setAuthorPostCollection(newCollection);
    setLatestCount(newCollection.length);
    history.replaceState(null, null, `/blog/tag/${props.pageContext.tagSlug}/?latest=${newCollection.length}`);
  }

  return <DefaultLayout location={props.location}
    headerFill="always"
    title={`${props.pageContext.tag} - Horangi Blog`}
    description={PAGE_DESCRIPTION}>
    <Head>
      <link rel="next" href={`/blog/tag/${props.pageContext.tagSlug}/?latest=${latestCount + ITEMS_PER_PAGE}`}/>
      <link rel="prev" href={`/blog/tag/${props.pageContext.tagSlug}/?latest=${latestCount - ITEMS_PER_PAGE}`}/>
    </Head>
    <div className={layoutStyle.headerPlaceholder}/>
    <section className="bg-white text-black">
      <div className="container relative mx-auto px-4 md:px-8 pt-4 sm:pt-20 z-10 max-w-5xl">
        <OutlineText tagName="h2"
          className="font-sans text-3xl sm:text-5xl font-black leading-none">
          Tag:
        </OutlineText>
        <h2 className="font-sans text-3xl sm:text-5xl font-black leading-none mt-2">
          {props.pageContext.tag}
        </h2>
      </div>
    </section>
    <section className="bg-white text-black mb-8 sm:mb-24">
      <div className="container relative mx-auto px-4 md:px-8 pt-8 sm:pt-16 pb-16 z-10 max-w-5xl">
        {renderLatestPosts()}
        {renderLoadMoreButton()}
      </div>
    </section>
  </DefaultLayout>;
}

export default React.memo(BlogTagTemplate);

export const QUERY = graphql`
  query BlogTagTemplateQuery($tag: String!) {
    latestPosts: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/\/content\/blog-posts\//"}
        frontmatter: {tags: {in: [$tag]}}
      },
      sort: {fields: [frontmatter___date], order: DESC},
      limit: 10
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
            author {
              name
            }
          }
          excerpt(pruneLength: 400)
          frontmatter {
            title
            excerpt
            category
            featureImage {
              childImageSharp {
                fluid(maxWidth: 1280, toFormat: JPG, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            date
            formattedDate: date(formatString: "MMM DD, YYYY")
          }
        }
      }
    }
  }
`;
